import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Utils, { getNameAreasByMaKhuVuc } from "general/utils/Utils";

import bulletinBoardApi from "api/bulletinBoardApi";
import devicesApi from "api/devicesApi";
import SelectArea from "components/SelectArea/SelectArea";
import SelectRadioStation from "components/SelectRadioStation/SelectRadioStation";
import ToastHelper from "general/helpers/ToastHelper";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./style.scss";
import _ from "lodash";

/**
 * Props:
 * * getDevicesApi (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 * 
 * Example:
 * ```js
 * import stationApi from '...';
 * 
 * <DevicesInfo
    getDevicesApi={(params) => stationApi.getStationDevices(station.id, params)}
    searchText={searchText}
  />
 * ``` 
 */
DevicesInfo.propTypes = {
  device: PropTypes.object,
  onSubmited: PropTypes.func,
  deviceType: PropTypes.string,
};

DevicesInfo.defaultProps = {
  device: {},
  onSubmited: () => {},
  deviceType: "speaker",
};
function DevicesInfo(props) {
  const { device, onSubmited, configured, ids } = props;
  const { deviceType } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const stations = useSelector(
    (state) => state.station.stations.DanhSachDaiTruyenThanh
  );
  const [currentArea, setCurrentArea] = useState();
  // const [currentStation, setCurrentStation] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedRadioStation, setSelectedRadioStation] = useState();
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputVolumeValue, setInputVolumeValue] = useState(0);
  const [inputApllyAllValue, setInputApllyAllValue] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [deviceIds, setDeviceIds] = useState([]);

  const changeSelectedArea = (area) => {
    setSelectedArea(area);
    setSelectedRadioStation({});
  };

  useEffect(() => {
    if (device) {
      setSelectedArea(
        getNameAreasByMaKhuVuc(device?.MaKhuVuc ? device.MaKhuVuc : -1)
      );
      setInputNameValue(device.TenThietBi ?? "");
      setInputVolumeValue(device.AmLuong ?? 0);
      const currentStation = _.find(stations, {
        MaDaiTruyenThanh: device.MaDaiTruyenThanh,
      });

      if (currentStation) {
        setSelectedRadioStation(currentStation);
      }
    }
  }, [device, stations]);

  // useEffect(() => {
  //   setSelectedRadioStation({});
  // }, [selectedArea]);

  useEffect(() => {
    if (ids) {
      setDeviceIds(ids);
    }
  }, [ids]);

  const saveChangeDevice = () => {
    if (selectedRadioStation.MaDaiTruyenThanh && inputNameValue.length > 0) {
      setSubmitDisable(true);
      debugger;
      devicesApi
        .updateIpRadio({
          CumLoaIDs: [device.CumLoaID],
          MaKhuVuc: selectedArea.MaKhuVuc,
          MaDaiTruyenThanh: selectedRadioStation.MaDaiTruyenThanh,
          TenThietBi: inputNameValue,
        })
        .then((data) => {
          setSubmitDisable(false);
          if (data.data.TrangThaiGui == 0) {
            ToastHelper.showSuccess("Đã lưu cấu hình");
            // onSubmited();
          }
        })
        .catch((err) => {
          setSubmitDisable(false);
          console.log(err);
        });
    } else {
      if (inputNameValue.length == 0) {
        ToastHelper.showError("Chưa nhập tên thiết bị");
      }
      if (!selectedRadioStation.id) {
        ToastHelper.showError("Chưa chọn đài truyền thanh");
      }
    }
  };

  const handleChangeDeviceVolumn = (e) => {
    const amLuong = e.target.value;
    setInputVolumeValue(amLuong);
    devicesApi
      .updateIpRadio({
        CumLoaIDs: [device.CumLoaID],
        AmLuong: amLuong,
      })
      .then((data) => {
        if (data.data.TrangThaiGui == 0) {
          // ToastHelper.showSuccess("Đã lưu cấu hình");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveChangeBulletinBoard = () => {
    if (inputNameValue.length > 0) {
      setSubmitDisable(true);
      bulletinBoardApi
        .updateBulletinBoard({
          id: device.deviceId,
          areaId: selectedArea.AreaId,
          name: inputNameValue,
        })
        .then((data) => {
          setSubmitDisable(false);
          if (data.data.status == 0) {
            ToastHelper.showSuccess("Đã lưu cấu hình");
            onSubmited();
          }
        })
        .catch((err) => {
          setSubmitDisable(false);
          console.log(err);
        });
    } else {
      if (inputNameValue.length == 0) {
        ToastHelper.showError("Chưa nhập tên thiết bị");
      }
    }
  };

  return (
    <>
      {configured == true ? (
        <div className="w-100 d-flex flex-xl-row flex-md-row flex-sm-column flex-column">
          <div
            className="flex-grow-1 ml-4 mr-2 mt-4 mb-4 d-flex flex-column"
            style={{ backgroundColor: "#F6F7FB", border: "1px solid #DBE3EF" }}
          >
            <div
              className="bg-white d-flex flex-column justify-content-center"
              style={{ height: 48, borderBottom: "1px solid #DBE3EF" }}
            >
              <p className="ml-6 font-weight-bolder">Thông tin chung</p>
            </div>
            <div
              className="bg-white d-flex flex-column justify-content-center mx-3 mt-3"
              style={{ border: "1px solid #DBE3EF" }}
            >
              <div className="row m-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  ID
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {deviceType == "bulletin-board"
                    ? device?.BangTinDienTuID
                    : device?.CumLoaID ?? ""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Tên thiết bị
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device?.TenThietBi ?? ""}
                </div>
              </div>
            </div>
            <div
              className="bg-white d-flex flex-column justify-content-center mx-3 mt-3"
              style={{
                backgroundColor: "#F6F7FB",
                border: "1px solid #DBE3EF",
              }}
            >
              <div className="row m-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Trạng thái
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device?.TrangThaiHoatDong &&
                  device?.TrangThaiHoatDong?.toUpperCase() == "IDLE"
                    ? "Đang nghỉ"
                    : device?.TrangThaiHoatDong}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Kết nối mạng
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device?.TrangThaiKetNoi ?? ""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Tín hiệu
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Địa chỉ IP
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device.ip ?? ""}
                </div>
              </div>
            </div>

            <div
              className="bg-white d-flex flex-column justify-content-center mx-3 mt-3"
              style={{
                backgroundColor: "#F6F7FB",
                border: "1px solid #DBE3EF",
              }}
            >
              <div className="row m-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Dòng tiêu thụ
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device.loadCurrent ?? "0"} A
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Phiên bản
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device?.PhienBanUngDung ?? ""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Lần cuối Online
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device.lastOnline
                    ? Utils.formatDateTime(device.lastOnline)
                    : ""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Loại thiết bị
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {/* {deviceType ? "Bảng tin điện tử" : " "} */}
                  {device?.TenLoaiThietBi}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Nhà cung cấp
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device?.MaNhaCungCap ?? ""}
                </div>
              </div>
            </div>

            <div
              className="bg-white d-flex flex-column justify-content-center m-3"
              style={{
                backgroundColor: "#F6F7FB",
                border: "1px solid #DBE3EF",
              }}
            >
              <div className="row m-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Khu vực
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {getNameAreasByMaKhuVuc(device?.MaKhuVuc)?.Ten}
                </div>
              </div>
              {deviceType == "bulletin-board" ? null : (
                <div className="row mx-5 mb-5">
                  <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                    Đài truyền thanh
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                    {device["radioStation.name"]
                      ? device["radioStation.name"]
                      : ""}
                  </div>
                </div>
              )}
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Kinh độ
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device.KinhDo ?? ""}
                </div>
              </div>
              <div className="row mx-5 mb-5">
                <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                  Vĩ độ
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-xl-8 textField">
                  {device.ViDo ?? ""}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow-1 ml-2 mr-4 mt-4 mb-4">
            <div
              style={{
                backgroundColor: "#F6F7FB",
                border: "1px solid #DBE3EF",
              }}
            >
              <div
                className="bg-white d-flex flex-column justify-content-center"
                style={{ height: 48, borderBottom: "1px solid #DBE3EF" }}
              >
                <p className="ml-6 font-weight-bolder">Cấu Hình</p>
              </div>

              <div
                className="bg-white d-flex flex-column justify-content-center mx-3 mt-3"
                style={{
                  backgroundColor: "#F6F7FB",
                  border: "1px solid #DBE3EF",
                }}
              >
                <div className="row m-5">
                  <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                    Tên thiết bị
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="deviceName"
                      placeholder="Nhập tên thiết bị"
                      style={{
                        color: "#4A5677",
                        fontFamily: "Inter",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      value={inputNameValue ?? ""}
                      onChange={(e) => {
                        setInputNameValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row mx-5 mb-5">
                  <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                    Khu vực
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                    <SelectArea
                      className=""
                      width={0}
                      onSelect={(area) => {
                        if (selectedArea) {
                          if (selectedArea.MaKhuVuc != area.MaKhuVuc) {
                            // setSelectedArea(area);
                            changeSelectedArea(area);
                          }
                        } else {
                          // setSelectedArea(area);
                          changeSelectedArea(area);
                        }
                      }}
                      rootAreaId={currentUser?.MaKhuVuc}
                      selectedArea={selectedArea}
                    />
                  </div>
                </div>
                {/* <div
                  className="mt-3 mb-1"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#9EA5BD",
                  }}
                >
                  Đài truyền thanh
                </div>
                <SelectRadioStation
                  className="bg-white rounded"
                  onSelect={(radioStation) => {
                    setSelectedRadioStation(radioStation);
                  }}
                  selectedRadioStation={
                    selectedRadioStation ??
                    findAreaByMaKhuVuc(currentUser?.MaKhuVuc)
                  }
                /> */}
                {deviceType == "bulletin-board" ? null : (
                  <div className="row mx-5 mb-5">
                    <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                      Đài truyền thanh
                    </div>
                    <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                      <SelectRadioStation
                        className=""
                        onSelect={(radioStation) => {
                          setSelectedRadioStation(radioStation);
                        }}
                        MaKhuVuc={
                          selectedArea
                            ? selectedArea?.MaKhuVuc
                            : currentUser?.MaKhuVuc
                        }
                        selectedRadioStation={selectedRadioStation}
                        deviceStationId={device.radioStationId}
                        noBorder={false}
                      />
                    </div>
                  </div>
                )}
              </div>
              {deviceType == "bulletin-board" ? null : (
                <div
                  className="bg-white d-flex flex-column justify-content-center m-3"
                  style={{
                    backgroundColor: "#F6F7FB",
                    border: "1px solid #DBE3EF",
                  }}
                >
                  <div className="row m-5">
                    <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                      Âm lượng
                    </div>
                    <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                      <div className="flex-grow-1 d-flex">
                        <input
                          type="range"
                          className="form-range flex-grow-1"
                          min="0"
                          max="100"
                          step="1"
                          value={inputVolumeValue}
                          onChange={(e) => {
                            setInputVolumeValue(e.target.value);
                            let data = {
                              DanhSachDiaBanNhan: [
                                {
                                  DichID: device.MaKhuVuc,
                                  TenDich: getNameAreasByMaKhuVuc(
                                    device?.MaKhuVuc
                                  )?.Ten,
                                },
                              ],
                              DanhSachThietBi: {
                                CumLoaID: [device.CumLoaID],
                              },
                              DieuKhienThietBi: {
                                MaLenh: "0",
                                ThamSo: e.target.value,
                              },
                            };
                            devicesApi.setIpradioVolume(data);
                          }}
                          id="inputVolume"
                        />
                        <input
                          type="text"
                          value={inputVolumeValue}
                          className="ml-2 text-center form-control font-size-bold px-1"
                          onChange={(e) => {
                            if (e.target.value.length == 0) {
                              setInputVolumeValue(0);
                              let data = {
                                DanhSachDiaBanNhan: [
                                  {
                                    DichID: device.MaKhuVuc,
                                    TenDich: getNameAreasByMaKhuVuc(
                                      device?.MaKhuVuc
                                    )?.Ten,
                                  },
                                ],
                                DanhSachThietBi: {
                                  CumLoaID: [device.CumLoaID],
                                },
                                DieuKhienThietBi: {
                                  MaLenh: "0",
                                  ThamSo: 0,
                                },
                              };
                              devicesApi.setIpradioVolume(data);
                              return;
                            }
                            try {
                              let newVolume = parseInt(e.target.value);
                              if (newVolume >= 0 && newVolume <= 100) {
                                setInputVolumeValue(newVolume);
                                let data = {
                                  DanhSachDiaBanNhan: [
                                    {
                                      DichID: device.MaKhuVuc,
                                      TenDich: getNameAreasByMaKhuVuc(
                                        device?.MaKhuVuc
                                      )?.Ten,
                                    },
                                  ],
                                  DanhSachThietBi: {
                                    CumLoaID: [device.CumLoaID],
                                  },
                                  DieuKhienThietBi: {
                                    MaLenh: "0",
                                    ThamSo: newVolume,
                                  },
                                };
                                devicesApi.setIpradioVolume(data);
                              }
                            } catch (e) {}
                          }}
                          style={{
                            height: 37,
                            width: 40,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row m-5">
                    <div className="col-auto col-sm-4 col-md-auto col-xl-4 labelField">
                      Áp dụng cho cả khu vực
                    </div>
                    <div className="login-checkbox col-auto col-sm-8 col-md-auto col-xl-8 p-0">
                      <input
                        type="checkbox"
                        // value={inputVolumeValue}
                        // onChange={(e) => {
                        //   setInputVolumeValue(e.target.value);
                        // }}
                        id="inputApllyAll"
                        checked={inputApllyAllValue}
                        onChange={(e) => {
                          setInputApllyAllValue(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="mx-3 my-4">
                <Button
                  className="w-100"
                  variant="primary"
                  size="lg"
                  disabled={submitDisable}
                  onClick={
                    deviceType == "bulletin-board"
                      ? saveChangeBulletinBoard
                      : saveChangeDevice
                  }
                >
                  <div className="font-weight-bold">Lưu chỉnh sửa</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100 d-flex flex-xl-row flex-md-row flex-sm-column flex-column">
          <div className="flex-grow-1 ml-4 mr-2 mt-4 mb-4">
            <div
              style={{
                backgroundColor: "#F6F7FB",
                border: "1px solid #DBE3EF",
              }}
            >
              <div
                className="bg-white d-flex flex-column justify-content-center"
                style={{ height: 48, borderBottom: "1px solid #DBE3EF" }}
              >
                <p className="ml-6 font-weight-bolder">Cấu Hình</p>
              </div>
              <div
                className="bg-white d-flex flex-column justify-content-center mx-3 mt-3"
                style={{ border: "1px solid #DBE3EF" }}
              >
                <div className="row m-5">
                  <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                    Khu vực
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                    <SelectArea
                      className=""
                      width={0}
                      onSelect={(area) => {
                        if (selectedArea) {
                          if (selectedArea.AreaId != area.AreaId) {
                            // setSelectedArea(area);
                            changeSelectedArea(area);
                          }
                        } else {
                          // setSelectedArea(area);
                          changeSelectedArea(area);
                        }
                      }}
                      rootAreaId={currentUser.areaId}
                      selectedArea={selectedArea}
                    />
                  </div>
                </div>
                <div className="row mx-5 mb-5">
                  <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelField">
                    Đài truyền thanh
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-xl-8 p-0">
                    <SelectRadioStation
                      className=""
                      onSelect={(radioStation) => {
                        setSelectedRadioStation(radioStation);
                      }}
                      areaId={
                        selectedArea ? selectedArea.AreaId : currentUser.areaId
                      }
                      selectedRadioStation={selectedRadioStation}
                      deviceStationId={device.radioStationId}
                      noBorder={false}
                    />
                  </div>
                </div>
              </div>
              <div className="m-3">
                <Button
                  className="w-100"
                  variant="primary"
                  size="lg"
                  disabled={submitDisable}
                  onClick={() => {
                    if (selectedRadioStation.id) {
                      setSubmitDisable(true);

                      if (deviceType === "speaker") {
                        devicesApi
                          .updateIpRadio({
                            ids: deviceIds,
                            radioStationId: selectedRadioStation.id,
                            areaId: selectedArea.AreaId,
                          })
                          .then((data) => {
                            setSubmitDisable(false);
                            if (data.data.status == 0) {
                              ToastHelper.showSuccess("Đã lưu cấu hình");
                              onSubmited();
                            }
                          })
                          .catch((err) => {
                            setSubmitDisable(false);
                            console.log(err);
                          });
                      } else {
                        // console.log(deviceIds)
                        bulletinBoardApi
                          .updateBulletinBoard({
                            id: deviceIds,
                            radioStationId: selectedRadioStation.id,
                            areaId: selectedArea.AreaId,
                          })
                          .then((data) => {
                            setSubmitDisable(false);
                            if (data.data.status == 0) {
                              ToastHelper.showSuccess("Đã lưu cấu hình");
                              onSubmited();
                            }
                          })
                          .catch((err) => {
                            setSubmitDisable(false);
                            console.log(err);
                          });
                      }
                    } else {
                      if (!selectedRadioStation.id) {
                        ToastHelper.showError("Chưa chọn đài truyền thanh");
                      }
                    }
                  }}
                >
                  <div className="font-weight-bold">Lưu chỉnh sửa</div>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="flex-grow-1 ml-2 mr-4 mt-4 mb-4"
            style={{ backgroundColor: "#F6F7FB", border: "1px solid #DBE3EF" }}
          >
            <div
              className="bg-white d-flex flex-column justify-content-center"
              style={{ height: 48, borderBottom: "1px solid #DBE3EF" }}
            >
              <p className="ml-6 font-weight-bolder">
                Danh sách thiết bị được chọn
              </p>
            </div>
            {deviceIds
              ? deviceIds.map((id, i) => {
                  // let title = `${speaker.name} - ${speaker["radioStation.name"]}`;
                  return (
                    <div
                      key={i}
                      className={`bg-white text-primary d-flex border mx-3 mt-3 ${
                        i == deviceIds.length - 1 ? "mb-3" : ""
                      }`}
                    >
                      <span
                        className="text-truncate flex-shrink-1"
                        style={{ padding: "12px 0 12px 16px" }}
                      >
                        <span className="text-dark font-weight-bold">
                          Thiết bị {i + 1} :
                        </span>{" "}
                        {id}
                      </span>
                      <span
                        className="flex-shrink-0 text-danger d-flex justify-content-center align-items-center"
                        style={{
                          padding: "12px 16px 12px 10px",
                          cursor: "pointer",
                          opacity: 0.7,
                        }}
                        onClick={() => {
                          // if (disableAll) return;
                          // speakers.splice(i, 1);
                          // setSpeakers([...speakers]);
                          const temp = [...deviceIds];
                          temp.splice(i, 1);
                          setDeviceIds(temp);
                        }}
                      >
                        <i className="text-danger fas fa-times-square" />
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </>
  );
}

export default DevicesInfo;
