import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import stationApi from "api/stationApi";
import ToastHelper from "general/helpers/ToastHelper";

export const thunkGetStations = createAsyncThunk(
  "station/all",
  async (params, thunkAPI) => {
    const res = await stationApi.getStations();
    return res;
  }
);

export const thunkGetStationsByAreaId = createAsyncThunk(
  "station/get",
  async (params, thunkAPI) => {
    const res = await stationApi.getStations(params);
    return res;
  }
);

export const thunkUpdateStation = createAsyncThunk(
  "station/update",
  async (params, thunkAPI) => {
    const res = await stationApi.updateStation(params);
    return { res, params };
  }
);

export const thunkCreateStation = createAsyncThunk(
  "station/create",
  async (params, thunkAPI) => {
    const res = await stationApi.createStation(params);
    return res;
  }
);

export const thunkDeleteStation = createAsyncThunk(
  "station/delete",
  async (params, thunkAPI) => {
    const { MaDaiTruyenThanh } = params;
    const res = await stationApi.deleteStation(MaDaiTruyenThanh);
    return { MaDaiTruyenThanh, res };
  }
);

const stationSlice = createSlice({
  name: "station",
  initialState: {
    isPending: true,
    stations: [],
    stationByArea: [],
  },
  reducers: {
    updateStations: (state, action) => {
      state.stations = action.payload;
    },
    check: (state, action) => {
      const index = action.payload;
      state.stations[index] = { ...state.stations[index], checked: true };
    },
    uncheck: (state, action) => {
      const index = action.payload;
      state.stations[index] = { ...state.stations[index], checked: false };
    },
  },
  extraReducers: {
    // Get All Stations By User
    [thunkGetStations.pending]: (state, action) => {
      state.isPending = true;
    },
    [thunkGetStations.rejected]: (state, action) => {
      state.isPending = false;
    },
    [thunkGetStations.fulfilled]: (state, action) => {
      state.isPending = false;
      let MoTa = action.payload?.data?.MoTa;
      let NoiDung = action.payload?.data?.NoiDung;
      //   const { MoTa, NoiDung } = action.payload.data;
      if (MoTa == "ThanhCong") {
        state.stations = NoiDung;
      }
    },

    // Get Stations By Area Id
    [thunkGetStationsByAreaId.pending]: (state, action) => {
      state.isPending = true;
    },
    [thunkGetStationsByAreaId.rejected]: (state, action) => {
      state.isPending = false;
    },
    [thunkGetStationsByAreaId.fulfilled]: (state, action) => {
      state.isPending = false;
      const { MoTa, NoiDung } = action?.payload?.data;
      if (MoTa == "ThanhCong") {
        console.log(
          "payload",
          MoTa,
          NoiDung,
          NoiDung?.DanhSachDaiTruyenThanh?.rows
        );
        state.stationByArea = NoiDung?.DanhSachDaiTruyenThanh;
      }
    },

    // Update Station
    [thunkUpdateStation.rejected]: (state, action) => {
      ToastHelper.showError("Sửa không thành công");
    },
    [thunkUpdateStation.fulfilled]: (state, action) => {
      const { res, params } = action.payload;
      const { id, name } = params;
      const { MoTa, NoiDung } = res.data;
      if (MoTa == "ThanhCong") {
        ToastHelper.showSuccess("Sửa thành công");
        state.stations.map((item, index) => {
          if (item.id == id) {
            state.stations[index] = { ...state.stations[index], name: name };
          }
        });
        state.stationByArea.map((item, index) => {
          if (item.id == id) {
            state.stationByArea[index] = {
              ...state.stationByArea[index],
              ...params,
            };
          }
        });
      } else ToastHelper.showError("Sửa không thành công");
    },

    // Add Station
    [thunkCreateStation.rejected]: (state, action) => {
      ToastHelper.showError("Thêm không thành công");
    },
    [thunkCreateStation.fulfilled]: (state, action) => {
      const { MoTa, NoiDung } = action.payload.data;
      if (MoTa == "ThanhCong") {
        ToastHelper.showSuccess("Thêm thành công");
        state?.stations?.DanhSachDaiTruyenThanh?.push(NoiDung?.DaiTruyenThanh);
        state?.stationByArea?.push(NoiDung?.DaiTruyenThanh);
      } else ToastHelper.showError("Thêm không thành công");
    },

    // Delete Station
    [thunkDeleteStation.rejected]: (state, action) => {
      ToastHelper.showError("Xóa không thành công");
    },
    [thunkDeleteStation.fulfilled]: (state, action) => {
      const { MaDaiTruyenThanh, res } = action.payload;
      console.log(MaDaiTruyenThanh);
      if (res.data.MoTa == "ThanhCong") {
        ToastHelper.showSuccess("Xóa thành công");
        if (Array.isArray(MaDaiTruyenThanh)) {
          for (let id of MaDaiTruyenThanh) {
            state.stations.DanhSachDaiTruyenThanh = state.stations.DanhSachDaiTruyenThanh.filter(
              (item) => item.MaDaiTruyenThanh != id
            );
            state.stationByArea = state.stationByArea.filter(
              (item) => item.MaDaiTruyenThanh != id
            );
          }
        }
      } else ToastHelper.showError("Xóa không thành công");
    },
  },
});

const { actions, reducer } = stationSlice;

export const { updateStations, check, uncheck } = actions;

export default reducer;
