import { default as axios } from "axios";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/bind";
import DateRangePicker from "react-bootstrap-daterangepicker-maxspan";
import styles from "./Report.module.scss";
import AppCard from "components/AppCard";
import AppPage from "components/AppPage";
import NavBar from "components/NavBar";
import { AppIcons, AppImages } from "general/constants/AppResource";
import deviceIcon from "../../assets/icons/device.png";
import newsIcon from "../../assets/icons/news.png";
import headSetIcon from "../../assets/icons/headset.png";
import { thunkGetReport } from "./reportSlice";
import Loading from "components/Loading";
import Empty from "components/Empty";
import Global from "general/Global";
import reportApi from "api/reportApi";

import SelectArea from "components/SelectArea/SelectArea";
import { setAllAreas, thunkGetAreas } from "pages/AreaManager/areaSlice";
import Utils, { findAreaById, sizeToCap } from "general/utils/Utils";
import styled from "styled-components";
import { SelectSort } from "pages/Category/components/SelectSort";
import "./style.scss";
import areaApi from "api/areaApi";
import { bulletinGetConfig } from "pages/BulletinManager/bulletinSlice";
import { useResizeDetector } from "react-resize-detector";

const cx = classNames.bind(styles);

Report.propTypes = {
  DeviceData: PropTypes.array,
};
Report.defaultProps = {
  DeviceData: [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
};

function Report(props) {
  const { DeviceData } = props;
  const { width, height, ref } = useResizeDetector();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const categories = useSelector((state) => {
    var tempCategories = _.map(state.bulletin.categories, function (category) {
      return {
        ...category,
        display: category.name,
      };
    });
    return [
      ...tempCategories,
      {
        id: -1,
        name: "Tất cả lĩnh vực",
        display: "Tất cả lĩnh vực",
      },
    ];
  });
  console.log(categories);

  const [dates, setDates] = useState([moment().subtract(6, "day"), moment()]);
  const [areasByUser, setAreasByUser] = useState([]);
  const [selectedArea, setSelectedArea] = useState();
  const [currentArea, setCurrentArea] = useState();
  const [categoryId, setCategoryId] = useState(-1);
  const [currentAreaId, setCurrentAreaId] = useState(1);
  const [bulletinCount, setBulletinCount] = useState(0);

  const {
    totalDevices,
    activeDevices,
    errorDevices,
    totalMembers,
    activeMembers,
    loadingState,
    radioStationCount,
    bulletins,
    bulletinsDetail,
    bulletinBoard,
    bulletinsCount,
    bulletinsTotalSize,
    bulletinsTotalTime,
    bulletinHistoryCount,
  } = useSelector((state) => state.report);

  const AttributeRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
      flex-direction: column;
      align-items: initial;
    }
  `;

  const AttributeTitle = styled.div`
    width: 140px;
    max-width: 40%;
    margin-right: 12px;
    text-align: right;

    @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
      width: initial;
      max-width: none;
      text-align: initial;
      margin-bottom: 5px;
    }
  `;

  const AttributeInput = styled.div`
    flex: 1;
  `;

  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      setAreasByUser(areas);
      dispatch(setAllAreas(areas));
    }
  }

  useEffect(() => {
    window.history.replaceState(null, "");
    dispatch(bulletinGetConfig());
  }, []);

  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    }
  }, [currentUser, allAreas]);

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    let areaId = selectedArea
      ? selectedArea.AreaId
      : currentUser
        ? currentUser.areaId
        : null;
    if (areaId) {
      setCurrentAreaId(areaId);
    }
  }, [selectedArea, currentUser.areaId]);

  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
      );
    }
  }, [areasByUser]);

  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  useEffect(() => {
    // debugger;
    dispatch(
      thunkGetReport({
        areaId: currentAreaId,
        startDate: dates[0].format("YYYY/MM/DD"),
        endDate: dates[1].clone().format("YYYY/MM/DD"),
        categoryId: categoryId,
      })
    );
    // time_convert();
  }, [dates, categoryId, currentAreaId]);

  const downloadFile = () => {
    reportApi.download().then((res) => {
      fileDownload(res.data, "report.xlsx");
    });
  };

  return (
    <AppPage
      className={cx("container")}
      backgroundImage={AppImages.imgBackground14}
      headerTitle={t("appName")}
    >
      <NavBar navBarTitle={t("appName")}></NavBar>
      <div ref={ref} className="d-flex flex-column flex-column-fluid my-2">
        <AppCard
          title={t("Reports")}
          buttonText={""}
          headerRight={
            <div className="d-flex flex-row align-items-center">
              <button className={cx("btn-download")} onClick={downloadFile}>
                <FontAwesomeIcon icon={faDownload} style={{ marginRight: 8 }} />
                {`${t("DownloadReport")}`}
              </button>
            </div>
          }
          additionalHeader={
            <>
              <div
                className={`d-flex flex-row align-items-center col-12 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pl-md-0
                  ${width > 824 ? "col-md-4" : "col-md-3"}
                `}
              >
                <p
                  className={`display-none font-weight-bold ${width > 890 ? "display-md-block mr-3" : ""
                    }`}
                  style={{ color: "#4A5677" }}
                >
                  {t("Display")}
                </p>
                <SelectArea
                  onSelect={(area) => {
                    setSelectedArea(area);
                  }}
                  rootAreaId={currentUser?.areaId}
                  selectedArea={selectedArea}
                  className="flex-grow-1"
                />
              </div>
              <div
                className={`d-flex flex-row align-items-center col-12 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0
                  ${width > 824 ? "col-md-4" : "col-md-3"}
                `}
              >
                <p
                  className={`display-none font-weight-bold ${width > 890 ? "display-md-block mr-3" : ""
                    }`}
                  style={{ color: "#4A5677" }}
                >
                  {t("Lĩnh vực")}
                </p>
                <AttributeInput>
                  <SelectSort
                    valueBtnStyle={{ width: "100%" }}
                    selections={categories}
                    currentValue={
                      _.find(categories, { id: categoryId })?.name || "Chọn"
                    }
                    onSelect={(item) => {
                      setCategoryId(item.id);
                    }}
                  // disable={disableAll}
                  />
                </AttributeInput>
              </div>

              <div className="col-12 col-md-4 px-smaller-md-0 mt-md-0 mt-4 d-flex flex-row pr-md-0">
                <DateRangePicker
                  minDate={moment().subtract(3, "month")}
                  maxDate={moment()}
                  // maxSpan = {{'days': 7}}
                  onApply={(event, picker) => {
                    if (
                      picker.startDate.format("DD/MM/YYYY") !=
                      picker.endDate.format("DD/MM/YYYY")
                    ) {
                      setDates([picker.startDate, picker.endDate]);
                    } else {
                      setDates([
                        picker.startDate,
                        picker.endDate.add(1, "day"),
                      ]);
                    }
                  }}
                >
                  <button className="toggle-button position-relative">
                    <span
                      className="mx-2"
                      style={{ color: "#4A5677", fontWeight: "600" }}
                    >
                      {t("from")}
                    </span>
                    <span style={{ color: "#3ACE5A" }}>
                      {dates[0] !== "--/--/----"
                        ? dates[0].format("DD/MM/YYYY")
                        : "--/--/----"}
                    </span>
                    <span
                      className="mr-2 ml-2"
                      style={{ color: "#4A5677", fontWeight: "600" }}
                    >
                      {t("to")}
                    </span>
                    <span className="mr-10" style={{ color: "#E64135" }}>
                      {dates[1] !== "--/--/----"
                        ? dates[1].format("DD/MM/YYYY")
                        : "--/--/----"}
                    </span>
                    <i
                      className="far fa-angle-down position-absolute top-50 translate-middle-y"
                      style={{ right: 12 }}
                    ></i>
                  </button>
                </DateRangePicker>
              </div>
            </>
          }
        >
          <div className={cx("content")}>
            {loadingState && (
              <div className={cx("loading")}>
                {loadingState && <Loading />}
                {/* {!totalDevices && !loadingState && (
                  <Empty
                    iconEmpty={AppIcons.icEmptyPack}
                    description={t("NoDataToDisplay")}
                    onClickButton={() =>
                      dispatch(
                        thunkGetReport({
                          areaId: currentAreaId,
                          startDate: dates[0].format("YYYY/MM/DD"),
                          endDate: dates[1].clone().format("YYYY/MM/DD"),
                          categoryId: categoryId,
                        })
                      )
                    }
                  />
                )} */}
              </div>
            )}

            {!loadingState && (
              <>
                <div className={cx("card")}>
                  <div className={cx("header")}>THIẾT BỊ TRUYỀN THANH</div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <img className={cx("img")} src={deviceIcon} />
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>{t("TotalDevices")}</span>
                    <div className={cx("value")}>
                      {totalDevices?.toLocaleString()}
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>{t("ActiveDevices")}</span>
                    <div className={cx("value")}>
                      <div className={cx("left")} style={{ color: "#3ACE5A" }}>
                        {activeDevices?.toLocaleString()}
                      </div>
                      <div className={cx("right")}>
                        /{totalDevices?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>{t("ErrorDevices")}</span>
                    <div className={cx("value")} style={{ color: "#E54135" }}>
                      {errorDevices?.toLocaleString()}
                    </div>
                  </div>
                  <div className={cx("footer")}></div>
                </div>

                <div className={cx("card")}>
                  <div className={cx("header")}>BẢN TIN PHÁT THANH</div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <i
                        className="fas fa-5x fa-podcast"
                        style={{ color: "#9EA5BD" }}
                      ></i>
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>
                      Số bản tin theo thời gian
                    </span>
                    <div className={cx("value")}>{bulletinsCount}</div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>
                      Số giờ phát theo thời gian
                    </span>
                    <div className={cx("value")}>
                      {Math.floor(bulletinsTotalTime / 3600)} giờ{" "}
                      {Math.floor((bulletinsTotalTime % 3600) / 60)} phút{" "}
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>
                      Tổng dung lượng phát theo thời gian
                    </span>
                    <div className={cx("value")}>
                      {sizeToCap(bulletinsTotalSize)}
                    </div>
                  </div>
                  <div
                    className={cx("footer")}
                    style={{ backgroundColor: "#ff66ff" }}
                  ></div>
                </div>

                <div className={cx("card")}>
                  <div className={cx("header")}>BẢNG TIN ĐIỆN TỬ</div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <i
                        className="fas fa-4x fa-photo-video"
                        style={{ color: "#9EA5BD" }}
                      ></i>
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>Đang phát</span>
                    <div className={cx("value")}>{bulletinBoard?.playing ?? 0}</div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>Đã kết nối</span>
                    <div className={cx("value")}>{bulletinBoard?.online ?? 0}</div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>Mất kết nối</span>
                    <div className={cx("value")}>{bulletinBoard?.offline ?? 0}</div>
                  </div>
                  <div
                    className={cx("footer")}
                    style={{ backgroundColor: "#ff4d4d" }}
                  ></div>
                </div>

                <div className={cx("card")}>
                  <div className={cx("header")}>{t("Enews").toUpperCase()}</div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <img className={cx("img")} src={newsIcon} />
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>{t("TotalNews")}</span>
                    <div className={cx("value")}>{bulletinHistoryCount}</div>
                  </div>
                  <div className={cx("row")}></div>
                  <div className={cx("row")}></div>
                  <div
                    className={cx("footer")}
                    style={{ backgroundColor: "#00B453" }}
                  ></div>
                </div>

                <div className={cx("card")}>
                  <div className={cx("header")}>ĐÀI TRUYỀN THANH</div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <img className={cx("img")} src={AppIcons.icTowerCell} />
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>
                      Tổng số đài truyền thanh
                    </span>
                    <div className={cx("value")}>{radioStationCount}</div>
                  </div>
                  <div className={cx("row")}></div>
                  <div className={cx("row")}></div>
                  <div
                    className={cx("footer")}
                    style={{ backgroundColor: "#c653c6" }}
                  ></div>
                </div>

                <div className={cx("card")}>
                  <div className={cx("header")}>
                    {t("EStatus").toUpperCase()}
                  </div>
                  <div className={cx("image")}>
                    <div className={cx("border")}>
                      <img className={cx("img")} src={headSetIcon} />
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>
                      {t("NumberBroadcaster")}
                    </span>
                    <div className={cx("value")}>
                      {totalMembers?.toLocaleString()}
                    </div>
                  </div>
                  <div className={cx("row")}>
                    <span className={cx("title")}>{t("ActiveStatus")}</span>
                    <div className={cx("value")}>
                      <div className={cx("left")} style={{ color: "#3ACE5A" }}>
                        {activeMembers?.toLocaleString()}
                      </div>
                      <div className={cx("right")}>
                        /{totalMembers?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className={cx("row")}></div>
                  <div
                    className={cx("footer")}
                    style={{ backgroundColor: "#40A4EC" }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </AppCard>
      </div>
    </AppPage>
  );
}

export default Report;
