import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import "./style.scss";
import providerApi from "api/providerApi";
import AppBreadcrumb from "components/AppBreadcrumb";
import Global from "general/Global";
import ModalCreateAccount from "../ModalCreateAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProvider,
  setSelectedAccount,
  setAccounts,
  deleteAccount,
} from "pages/ProviderManager/providerSlice";
import ToastHelper from "general/helpers/ToastHelper";
import useSort from "hooks/useSort";
import Utils from "general/utils/Utils";
import ModalDelete from "components/AppModal/ModalDelete";
import areaApi from "api/areaApi";
import { setAllAreas } from "pages/AreaManager/areaSlice";
import { thunkGetStations } from "pages/RadioStationManager/stationSlice";
import CheckBox from "components/CheckBox";

function TableListAccounts(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const {
    searchText,
    selectedArea,
    selectedItems,
    handleSetSelectedItems,
    handleSetFuncDeleteItems,
  } = props;

  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);
  const [editAccount, setEditAccount] = useState();
  const [deletableAccount, setDeletableAccount] = useState();
  const [modalCreateAccountShowing, setModalCreateAccountShowing] =
    useState(false);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { selectedProvider, accounts } = useSelector((state) => state.provider);
  const { allAreas } = useSelector((state) => state.area);
  const { stations } = useSelector((state) => state.station);
  const { directionIndicator, sortOnClick } = useSort(accounts, (sortedArray) =>
    dispatch(setAccounts(sortedArray))
  );

  const filteredAccounts = useMemo(() => {
    let filteredItems = Utils.filter(accounts, "account", searchText);
    if (selectedArea?.AreaId === 1 || !selectedArea) return filteredItems;
    filteredItems = filteredItems.filter(
      (item) =>
        item.areaId === selectedArea?.AreaId ||
        allAreas.find((area) => area.id === item.id)?.parentId ===
          selectedArea?.AreaId
    );
    return filteredItems;
  }, [searchText, accounts, selectedArea]);

  // MARK: --- Functions ---

  // Get accounts
  async function getAccounts() {
    setIsLoading(true);
    const res = await providerApi.getAccountOfProviders(
      selectedProvider.MaNhaCungCap
    );
    const { MoTa, NoiDung } = res.data;
    if (MoTa == "ThanhCong") {
      dispatch(setAccounts(NoiDung.TaiKhoanNhaCungCap));
    }

    setIsLoading(false);
  }

  function handleDeleteAccount(id) {
    setModalDeleteShowing(true);
    setDeletableAccount(id);
  }

  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      dispatch(setAllAreas(areas));
    }
  }

  function Status({ status }) {
    let bgColor;
    let textColor;
    let text = status.toLowerCase();
    text = text[0].toUpperCase() + text.substring(1);
    switch (status) {
      case "ACTIVE":
        bgColor = "#20A1441A";
        textColor = "#20A144";
        break;
      case "DISACTIVE":
        bgColor = "#6b6b6b30";
        textColor = "#6b6b6b";
        break;
      case "ERROR":
        bgColor = "#E6413510";
        textColor = "#E64135";
        break;
    }

    return (
      <div
        className={`d-flex justify-content-center align-items-center rounded px-2 py-1 font-weight-bold`}
        style={{ backgroundColor: bgColor, color: textColor }}
      >
        <div className="text-center">{t(`${text}`)}</div>
      </div>
    );
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    getAccounts();
    getAreas();
    dispatch(thunkGetStations());
    handleSetFuncDeleteItems(() => (id) => handleDeleteAccount(id));
    return () => {
      dispatch(setAccounts([]));
      handleSetSelectedItems([]);
    };
  }, []);

  return (
    <>
      <AppBreadcrumb
        items={[
          {
            name: selectedProvider.Ten,
            onClick: () => {
              dispatch(setSelectedProvider(null));
            },
          },
        ]}
        text={`Danh sách tài khoản (${accounts?.length} tài khoản)`}
      />

      <DataTable
        cols={[0, 15, 20, 20, 15, 1, 1, 20, 20]}
        headItems={[
          <CheckBox
            checked={
              filteredAccounts.length === selectedItems.length &&
              filteredAccounts.length !== 0
            }
            onCheck={() => handleSetSelectedItems(filteredAccounts)}
            onUncheck={() => handleSetSelectedItems([])}
          />,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("account")}
          >
            <p>
              {t("Account")}
              {directionIndicator("account")}
            </p>
          </div>,
          t("ServerAddress"),
          t("RadioStation"),
          t("Area"),
          t("Status"),
          t("Active"),
          t("API Key"),
          t("Manage"),
        ]}
        // row
        dataItems={[
          ...filteredAccounts
            ?.slice(step * (page - 1), step * page)
            .map((item, index) => {
              let isRowCheck = !!selectedItems?.find(
                (account) => account.id === item.id
              );
              return {
                isRowCheck: isRowCheck,
                rowClick: () => {},
                data: [
                  {
                    comp: (
                      <CheckBox
                        checked={isRowCheck}
                        onCheck={() =>
                          selectedItems?.find(
                            (account) => account.id === item.id
                          ) !== null &&
                          handleSetSelectedItems([...selectedItems, item])
                        }
                        onUncheck={() =>
                          handleSetSelectedItems(
                            selectedItems.filter(
                              (account) => account.id !== item.id
                            )
                          )
                        }
                      />
                    ),
                  },
                  {
                    comp: (
                      <div
                        className="d-flex flex-row align-items-center position-relative"
                        style={{ minWidth: 100 }}
                      >
                        <i className="fas fa-user-tie text-primary"></i>
                        <span
                          className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap pr-6 word-break-all"
                          style={{ left: 22 }}
                        >
                          {item.TaiKhoan}
                        </span>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div
                        className="position-relative"
                        style={{ minWidth: 140 }}
                      >
                        <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap word-break-all">
                          {item.BaseURL || ""}
                        </p>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div className="position-relative">
                        <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                          {/* {stations?.find(
                            (station) => station.id === item.radioStationId
                          )?.name || ""} */}
                        </p>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div
                        className="position-relative"
                        style={{ minWidth: 70 }}
                      >
                        <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                          {allAreas?.find((area) => area.id === item.areaId)
                            ?.name || ""}
                        </p>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div className="position-relative" style={{ width: 115 }}>
                        <div className="w-100 position-absolute w-100 top-50 translate-middle-y">
                          <Status status={item.TrangThai} />
                        </div>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div className="w-100 d-flex justify-content-center">
                        <span style={{ color: "#3ACE5A" }}>
                          {item.activeDevices}
                        </span>
                        /{item.deviceNumber}
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div
                        className="position-relative"
                        style={{ minWidth: 150 }}
                      >
                        <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                          {item["apiKey.apiKey"] || ""}
                        </p>
                      </div>
                    ),
                    onClick: () => {
                      dispatch(setSelectedAccount(item));
                    },
                  },
                  {
                    comp: (
                      <div style={{ width: 80 }}>
                        <i
                          className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                          onClick={() => {
                            setModalCreateAccountShowing(true);
                            setEditAccount(item);
                          }}
                        />
                        <i
                          className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                          onClick={() => {
                            handleDeleteAccount(item);
                          }}
                        ></i>
                        <i
                          className="fas fa-copy cursor-pointer text-hover-primary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              item.httnAPIKey || ""
                            );
                            ToastHelper.showSuccess(t("Copied"));
                          }}
                        ></i>
                      </div>
                    ),
                  },
                ],
              };
            }),
        ]}
        step={step}
        onRefresh={() => getAccounts()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white"
        total={filteredAccounts.length}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />

      <ModalCreateAccount
        show={modalCreateAccountShowing}
        onClose={() => setModalCreateAccountShowing(false)}
        account={editAccount}
      />

      <ModalDelete
        show={modalDeleteShowing}
        title={t("DeleteProviderAccount")}
        text={
          Array.isArray(deletableAccount)
            ? `Bạn có chắc muốn xóa ${deletableAccount.length} tài khoản không?`
            : `Bạn có chắc muốn xóa tài khoản ${deletableAccount?.account} không?`
        }
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={async () => {
          const res = await providerApi.deleteAccount(
            Array.isArray(deletableAccount)
              ? deletableAccount
              : deletableAccount.id
          );
          const { result } = res.data;

          if (result == "success") {
            ToastHelper.showSuccess(t("DeletedAccountSuccessfully"));
            dispatch(
              deleteAccount(
                Array.isArray(deletableAccount)
                  ? deletableAccount
                  : deletableAccount.id
              )
            );
            handleSetSelectedItems([]);
          }
        }}
      />
    </>
  );
}

export default TableListAccounts;
