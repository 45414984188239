import axiosClient from "./axiosClient";

const devicesApi = {
  // ipradio
  getDevicesInArea: (params) => {
    const url = `/devices/ipradio`;
    return axiosClient.get(url, { params });
  },
  getDetailIpRadio: (id) => {
    const url = `/devices/ipradio/detail?CumLoaID=${id}`;
    return axiosClient.get(url);
  },
  createIpRadio: (params) => {
    const url = `/devices/ipradio/create`;
    return axiosClient.post(url, params);
  },
  updateIpRadio: (params) => {
    const url = `/devices/ipradio/update`;
    return axiosClient.post(url, params);
  },
  deleteIpRadio: (params) => {
    const url = `/devices/ipradio/delete`;
    return axiosClient.post(url, params);
  },
  setIpradioVolume: (params) => {
    const url = `/devices/ipradio/control`;
    return axiosClient.post(url, params);
  },

  // digitalsignage
  getDigitalsignage: (params) => {
    const url = `/devices/digitalsignage`;
    return axiosClient.get(url, { params });
  },

  getDetailDigitalSignage: (id) => {
    const url = `/devices/digitalsignage/detail?BangTinDienTuID=${id}`;
    return axiosClient.get(url);
  },
  createDigitalSignage: (params) => {
    const url = `/devices/digitalsignage/create`;
    return axiosClient.post(url, params);
  },
};

export default devicesApi;
