import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  formatFileObj,
  getAllSupportedMimeTypes,
  getMicDevices,
} from "../../../../general/utils/Utils";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import Loading from "../../../../components/Loading";
import Visualizer from "../Visualizer";
import { SelectSort } from "pages/Category/components/SelectSort";
import _ from "lodash";
import useWebSocket from "react-use-websocket";
const MicRecorder = require("mic-recorder-to-mp3");
const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;
const RECORD_STATE = {
  START: "START",
  STOP: "STOP",
  PAUSE: "PAUSE",
};
const AUDIO_SUPPORTED_MIME_TYPES = getAllSupportedMimeTypes();

let micOptions = [];

export default function ChooseSourceMic({
  show,
  disableAll,
  recordFiles,
  onRecordFilesChange,
  playDirect,
  onPlayDirectChange,
  micID,
  onChangeMicID,
}) {
  let mediaRecorder = useRef(null);
  let audioChunks = useRef([]);
  let audioRef = useRef([]);
  let audioStream = useRef(null);
  const micRecoder = useRef(null);
  const intervalId = useRef(null);
  const [mics, setMics] = useState([]);
  // const [micID, setMicID] = useState("");
  const [showRecordModel, setShowRecordModal] = useState(false);
  const [recordState, setRecordState] = useState(RECORD_STATE.STOP);
  const [showLoadingInRecordModal, setShowLoadingInRecordModal] =
    useState(false);
  //websocket
  // const { sendMessage, readyState, getWebSocket } = useWebSocket(
  //   // `wss://htttn.ipfm.vn/ws_v2?wsId=${12345}&banTinID=${"12345"}&type=RTMP`,
  //   process.env.WSS_URL,
  //   {
  //     onMessage: (message) => {
  //       console.log(message);
  //     },
  //     filter: () => false,
  //     shouldReconnect: (_closeEvent) => true, //what does the close event look like when caused by the 2 hour limit? Is there a different error code that you could use? I would look into returning false in this case and adding the following option:
  //     retryOnError: true, //With this true, and returning false in the 'shouldReconnect' will avoid trying to reconnect twice for the same event
  //     onClose: () => console.log("WEBSOCKET closing"),
  //     onError: (err) => console.log("WEBSOCKET error", err),
  //     onOpen: () => console.log("WEBSOCKET open"),
  //   }
  // );
  // get media devices
  useEffect(async () => {
    setMics(await getMicDevices());
  }, []);

  useEffect(() => {
    if (audioRef.current.length) {
      audioRef.current.forEach((ref) => ref?.load());
    }
  }, [recordFiles]);

  useEffect(() => {
    if (!show && audioRef.current.length) {
      audioRef.current.forEach((ref) => ref.pause());
    }
  }, [show]);

  useEffect(() => {
    if (mics)
      micOptions = mics.map(({ deviceId, groupId, kind, label }) => ({
        deviceId: deviceId,
        groupId: groupId,
        kind: kind,
        label: label,
        id: deviceId,
        value: deviceId,
        display: label || deviceId,
      }));
  }, [mics]);

  // useEffect(() => {
  //   if (playDirect) {
  //     onPlayDirect();
  //   } else {
  //     if (intervalId.current) {
  //       clearInterval(intervalId.current);
  //       intervalId.current = null;
  //     }
  //     if (micRecoder.current) {
  //       micRecoder.current.stop();
  //     }
  //     sendMessage(JSON.stringify({ command: "STOP_RTMP", BanTinId: "12345" }));
  //   }
  // }, [playDirect, micID, intervalId.current]);

  // request permission to get devices
  const onCheckDevices = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then(async function (stream) {
        // steam.getSource()
        setMics(await getMicDevices());
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(function (err) {});
  };

  const onPlayDirect = () => {
    if (micID) {
      let config = {
        bitRate: 128,
      };

      config.deviceId = micID;

      if (!micRecoder.current) {
        micRecoder.current = new MicRecorder(config);
      }

      micRecoder.current
        .start()
        .then(() => {
          sendMessage(
            JSON.stringify({ command: "START_RTMP", BanTinId: "12345" })
          );
          const id = setInterval(() => {
            micRecoder.current.getMp3().then(([buffer, blob]) => {
              for (let i = 0; i < buffer.length; i++) {
                let arrayBuffer = new Uint8Array(buffer[i]);
                let sendData = new Uint8Array(arrayBuffer.length);
                sendData.set(arrayBuffer);
                sendMessage(sendData);
              }
            });
          }, 1000);
          intervalId.current = id;
        })
        .catch((exception) => {
          console.error(exception);
          if (exception instanceof OverconstrainedError) {
            toast({
              icon: "error",
              title: "Truy cập mic lỗi.",
            });
          }
        });
    }
  };

  const onShowRecordModal = () => {
    if (!AUDIO_SUPPORTED_MIME_TYPES.length) {
      return ToastHelper.showError("Trình duyệt không hỗ trợ ghi âm");
    }

    let constraints = { audio: true, video: false };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async function (stream) {
        audioStream.current = stream;
        audioRef.current && audioRef.current.forEach((ref) => ref.pause());
        setShowRecordModal(true);
        mediaRecorder.current = new MediaRecorder(
          stream
          // , {
          // mimeType: AUDIO_SUPPORTED_MIME_TYPES[0].mimeType
          // }
        );

        mediaRecorder.current.addEventListener("dataavailable", (event) => {
          audioChunks.current.push(event.data);
          // debugger;
          // sendMessage(event.data);
        });

        mediaRecorder.current.addEventListener("stop", async () => {
          setShowLoadingInRecordModal(true);
          // const blob = new Blob(audioChunks.current, {type: 'audio/mpeg'})
          const blob = new Blob(audioChunks.current);
          // let file = new File([blob], `record.${AUDIO_SUPPORTED_MIME_TYPES[0].ext}`);
          let file = new File([blob], `record.mp3`, { type: "audio/mpeg" });
          // setAudioURL([URL.createObjectURL(file)]);

          let fileObj = await formatFileObj(file);
          onRecordFilesChange([fileObj]);
          setShowLoadingInRecordModal(false);
          setShowRecordModal(false);
          stream.getTracks().forEach((track) => track.stop());
        });
      })
      .catch(function (err) {
        console.error(err);
        ToastHelper.showError(
          "Không thể ghi âm, vui lòng cấp quyền truy cập thiết bị"
        );
      });
  };

  const onStartRecord = () => {
    if (!mediaRecorder.current) {
      return;
    }

    audioChunks.current = [];

    mediaRecorder.current.start();
    setRecordState(RECORD_STATE.START);
  };

  const onStopRecord = () => {
    if (!mediaRecorder.current) {
      return;
    }
    mediaRecorder.current.stop();
    setRecordState(RECORD_STATE.STOP);
  };

  // toggle pause/resume
  const onToggleRecordState = (pause = true) => {
    if (!mediaRecorder.current) {
      return;
    }

    if (pause) {
      mediaRecorder.current.pause();
      setRecordState(RECORD_STATE.PAUSE);
    } else {
      mediaRecorder.current.resume();
      setRecordState(RECORD_STATE.START);
    }
  };

  return (
    <div
      style={{
        background: "#F6F7FB",
        border: "1px solid #DBE3EF",
        display: show ? "" : "none",
      }}
    >
      <div
        className="bg-white border-bottom d-flex align-items-center text-nowrap"
        style={{
          padding: "20px 12px",
        }}
      >
        <label className="Regular_13 mb-0" style={{ marginRight: 12 }}>
          Chọn thiết bị
        </label>
        <SelectSort
          className="flex-grow-1"
          selections={micOptions}
          disable={disableAll}
          currentValue={
            _.find(micOptions, { deviceId: micID })?.display ?? "Chọn"
          }
          onSelect={(item) => onChangeMicID(item.deviceId)}
        />
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          padding: 10,
        }}
      >
        <button
          style={{ margin: "0 8px" }}
          className="Bold_13 btn btn-white border"
          onClick={onCheckDevices}
          disabled={disableAll}
        >
          <i className="fas fa-wrench" style={{ marginRight: 8 }} />
          Kiểm Tra Thiết Bị
        </button>
        <button
          style={{ margin: "0 8px" }}
          className={`Bold_13 btn border ${
            playDirect ? "btn-primary" : "btn-white"
          }`}
          onClick={() => {
            onPlayDirectChange(!playDirect);
          }}
          disabled={disableAll}
        >
          <i className="fas fa-microphone" />
          Phát Trực Tiếp
        </button>
        <PrimaryButton
          style={{ margin: "0 8px" }}
          onClick={() => {
            onPlayDirectChange(false);
            onShowRecordModal();
          }}
          disabled={disableAll}
        >
          <i className="fas fa-signal-stream" />
          Ghi Âm
        </PrimaryButton>
      </div>
      {!playDirect &&
        !!recordFiles.length &&
        recordFiles.map(({ src }, i) => (
          <audio
            key={src}
            className="w-100"
            title={"record"}
            controls={true}
            ref={(el) => (audioRef.current[i] = el)}
            // ref={audioRef}
          >
            <source src={src}></source>
          </audio>
        ))}
      <Modal
        centered={true}
        isOpen={showRecordModel}
        toggle={() => {
          if (!showRecordModel) {
            setShowRecordModal(true);
          } else {
            if (recordState === RECORD_STATE.STOP) {
              setShowRecordModal(false);
              if (audioStream.current)
                audioStream.current
                  .getTracks()
                  .forEach((track) => track.stop());
            }
          }
        }}
        backdrop={showLoadingInRecordModal ? "static" : true}
      >
        {/*<ModalHeader>*/}
        {/*  <div>*/}
        {/*    Xóa Tập Tin*/}
        {/*  </div>*/}
        {/*</ModalHeader>*/}
        {!!showLoadingInRecordModal && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              background: "rgba(255,255,255,0.8)",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
        <ModalBody>
          <Visualizer
            mediaStream={audioStream.current}
            width={400}
            height={400}
          />
          <div className="Regular_13 text-center">
            Bạn hãy nói vào thiết bị để thử âm thanh
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex m-0 w-100">
            {recordState === RECORD_STATE.STOP && (
              <PrimaryButton className={"col px-0"} onClick={onStartRecord}>
                <i className="fas fa-play-circle" />
                Bắt Đầu
              </PrimaryButton>
            )}

            {recordState === RECORD_STATE.START && (
              <div style={{ paddingRight: 8, paddingLeft: 0 }} className="col">
                <button
                  className="btn btn-white border w-100"
                  onClick={() => onToggleRecordState(true)}
                >
                  <i className="fas fa-pause-circle" />
                  Tạm Dừng
                </button>
              </div>
            )}

            {recordState === RECORD_STATE.PAUSE && (
              <div style={{ paddingRight: 8, paddingLeft: 0 }} className="col">
                <button
                  className="Bold_13 btn btn-success w-100"
                  onClick={() => onToggleRecordState(false)}
                >
                  <i className="fas fa-play-circle" />
                  Tiếp Tục
                </button>
              </div>
            )}

            {recordState !== RECORD_STATE.STOP && (
              <div style={{ paddingLeft: 8, paddingRight: 0 }} className="col">
                <button
                  className="Bold_13 btn btn-danger w-100"
                  onClick={onStopRecord}
                >
                  <i className="fas fa-stop-circle" />
                  Kết Thúc
                </button>
              </div>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
