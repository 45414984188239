import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { Account, Area } from "../../../../general/types/index.d";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import { useDispatch, useSelector } from "react-redux";
import KeenChangeAvatar from "../../../../components/Keen/KeenChangeAvatar";
import KeenSelectOption from "../../../../components/Keen/KeenSelectOption";
import ReactSwitch from "react-switch";
import userApi from "../../../../api/userApi";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import Utils, { findNode } from "general/utils/Utils";
import LoadingOverlay from "components/LoadingOverlay";
import CheckBox from "components/CheckBox";
import "./styles.scss";
import CardDetail from "components/CardDetail";
import CardDetailHaveButton from "components/CardDetailHaveButton";
import AccountCard from "components/AccountCard";
import NavBar from "components/NavBar";
import AppPage from "components/AppPage";
import { useHistory, useParams } from "react-router-dom";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import ModalDeleteAccount from "pages/AccountManager/components/ModalDeleteAccount";
import SelectProviderDropdown from "pages/ProviderManager/components/SelectProviderDropdown";
import TreeItemDropdown from "pages/AccountManager/components/TreeItemDropdown";

/**
 * @description depend on `formik` and `yup` lib
 *
 * @param {Object} props
 * @param {string=} props.className wrapper component class name
 * @param {boolean=} props.show is show or not
 * @param {(success?:boolean)=>void=} props.onDismiss onDismiss callback
 * @param {()=>void=} props.handleDelete when press delete button
 * @param {Account} props.account account
 */

let hiddenEmail;

export default function EditAccount(props) {
  let { id } = useParams();
  const { selectedAccount } = useSelector((state) => state?.account);
  const [account, setAccount] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDelteLoading] = useState(false);
  /**
   * @type {Area[]}
   */
  const { allAreas, listArea } = useSelector((state) => state.area);
  /**
   * @type {Account}
   */
  const currentUser = useSelector((state) => state.auth.current);
  let { className, show, onDismiss, handleDelete } = props;
  let isEditing = id == "add" ? false : true;

  const [showLoading, setShowLoading] = useState(false);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter =
    process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter =
    process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText =
    "Mật khẩu gồm ít nhất " + passwordMinLength + " kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] =
    useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);
  const accountStateOption = [
    { value: "active", text: t("Active") },
    { value: "blocked", text: t("Blocked") },
  ];

  useEffect(() => {
    if (passwordHaveCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNonCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNumber == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    setPasswordRequirement(regExp);
  }, []);

  const fetchUserInfo = () => {
    userApi.getUserInfo(id).then((res) => {
      // console.log(res.data.user);
      setAccount(res?.data?.user);
    });
  };

  useEffect(() => {
    if (id == "add") {
    } else {
      // fetchUserInfo();
      setAccount(selectedAccount);
    }
  }, []);

  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  const showIndicating = () => {
    setShowLoading(true);
  };

  const hideIndicating = () => {
    setShowLoading(false);
  };

  /**
   *
   * @param {boolean=} success
   */
  const handleClose = (success) => {
    _.isFunction(onDismiss) && onDismiss(success);
    hideIndicating();
    setTimeout(formik.resetForm);
  };

  const hideDeleteModal = () => {
    setModalDeleteShowing(false);
  };

  let passwordYup = isEditing
    ? {}
    : {
      password: Yup.string()
        .required(t("enterPassword"))
        .min(passwordMinLength, passwordRequirementErrorText)
        .max(passwordMaxLength, passwordRequirementErrorText)
        .matches(passwordRequirement, passwordRequirementErrorText)
        .matches(/^(?!.*[\s])/, "Có khoảng trắng trong mật khẩu")
        .nullable(),
    };
  const validationSchema = Yup.object().shape({
    TaiKhoan: Yup.string().required(t("enterAccount")),
    ...passwordYup,
    vbeeWordLimit: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
    vbeeWordUsed: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
    // MaKhuVuc: Yup.number().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).nullable(),
    SDT: Yup.string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
        message: t("InvalidPhone"),
      })
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      ...account,
      MaKhuVuc: account?.MaKhuVuc ?? account?.area?.id,
      vbeeWordLimit: account?.vbee?.monthLimitCharacters,
      vbeeWordUsed: account?.vbee?.monthUsedCharacters,
      // TaiKhoan: account?.vbee?.TaiKhoan,
      vbeeApplicationId: account?.vbee?.applicationId,
      vbeeDictionaryId: account?.vbee?.dictionaryId,
      vbeeToken: account?.vbee?.token,
      broadcast: !!account?.broadcast,
      editAccount: !!account?.editAccount,
      acceptProgram: !!account?.acceptProgram,
      editProgram: !!account?.editProgram,
      editSpeaker: !!account?.editSpeaker,
      useTTS: !!account?.useTTS,
      managerAccountProvider: !!account?.managerAccountProvider,
      managerArea: !!account?.managerArea,
      managerProvider: !!account?.managerProvider,
      managerRadioStation: !!account?.managerRadioStation,
      avatarFile: null,
      password: null,
      state: account?.state ?? "active",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("====================================");
      console.log(values);
      console.log("====================================");

      try {
        if (isNaN(values.MaKhuVuc)) {
          hideIndicating();
          return ToastHelper.showError(t("MissingArea"));
        }

        if (values.vbee)
          values["vbee"] = JSON.stringify({
            monthLimitCharacters: values.vbeeWordLimit,
            monthUsedCharacters: values.vbeeWordUsed,
            // TaiKhoan: values.TaiKhoan,
            applicationId: values.vbeeApplicationId,
            // dictionaryId: values.vbeeDictionaryId,
            token: values.vbeeToken,
          });



        showIndicating();

        if (!isEditing) {
          let { result } = (
            await userApi.createUser({
              ...values,
              MatKhau: await Utils.hash(values.password),
            })
          ).data;
          if (result == "success") {
            ToastHelper.showSuccess(t("CreateUserSuccess"));
          } else ToastHelper.showError(t("CreateUserFailed"));
        } else {
          setLoading(true);
          values["userId"] = values.id;
          let { result } = (
            await userApi.updateUserInfo({
              ...values,
              MatKhau: await Utils.hash(values.password)
            })
          ).data;
          if (result == "success") {
            setLoading(false);
            ToastHelper.showSuccess(t("UpdateUserSuccess"));
            fetchUserInfo();
          } else ToastHelper.showError(t("UpdateUserFailed"));
        }
      } catch (error) {
        setLoading(false);
        // ToastHelper.showError(error.message);
      }
      hideIndicating();
      setLoading(false);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const TitleWrapper = useCallback(
    ({ children, className }) => (
      <span className={`Bold_13 ${className}`}>{children}</span>
    ),
    []
  );

  return (
    <AppPage headerTitle={isEditing ? t("UserInfo") : t("Thêm người dùng mới")}>
      <NavBar
        navBarTitle={isEditing ? t("UserInfo") : t("Thêm người dùng mới")}
      ></NavBar>
      <div className=" d-flex flex-column flex-column-fluid my-2 ">
        <AccountCard
          title={t("UserInfo")}
          clickSave={() => formik.handleSubmit()}
          clickDelete={() => setModalDeleteShowing(true)}
          blueButtonText={isEditing ? t("SaveChanges") : t("AddUser")}
          whiteButtonText={isEditing ? t("Delete") : null}
          loading={loading}
        >
          <form autoComplete="off" className="container-fluid bg-white w-100 ">
            <div className="row">
              <div className="col-md-6 ">
                <CardDetail
                  title={t("Account")}
                  className="justify-content-center"
                  bottomComponent={
                    <div
                      className=" d-flex flex-column"
                      style={{ background: "white", padding: "10px" }}
                    >
                      <TitleWrapper className="mb-2">
                        {t("ManageUserStatus")}
                      </TitleWrapper>

                      <SelectProviderDropdown
                        className="Normal_12 font-weight-bold"
                        name="state"
                        options={accountStateOption}
                        renderValue={(value) => (
                          <p
                            className={
                              formik.values.state == "blocked"
                                ? "text-danger"
                                : "text-primary"
                            }
                          >
                            {_.find(accountStateOption, { value: value })?.text}
                          </p>
                        )}
                        renderDropdownItem={(item) => (
                          <div
                            key={item.id}
                            className={`w-100 px-5 py-3 d-flex flex-row cursor-pointer font-weight-bold Regular_13 ${formik.values.state == item.value &&
                              "bg-primary text-white"
                              }`}
                          >
                            <p className="">{item.text}</p>
                          </div>
                        )}
                        onItemClick={(item) => {
                          formik.getFieldHelpers("state").setValue(item.value);
                        }}
                        fieldHelpers={formik.getFieldHelpers("state")}
                        fieldMeta={formik.getFieldMeta("state")}
                        fieldProps={formik.getFieldProps("state")}
                      />
                    </div>
                  }
                // buttonTitle={t("ChangeProfilePicture")}
                >
                  <KeenChangeAvatar
                    className="justify-content-center align-items-center d-flex text-hover-danger"
                    imageClassName="w-200px h-200px"
                    disabledRevokeUrl
                    name="avatarLink"
                    fieldProps={formik.getFieldProps("avatarLink")}
                    fieldMeta={formik.getFieldMeta("avatarLink")}
                    onFileSelected={([file]) => {
                      formik.getFieldHelpers("avatarFile").setValue(file);
                    }}
                  />
                </CardDetail>

                <CardDetail title={t("Authority")}>
                  <div className="">
                    <div
                      className="d-flex flex-column pl-4 pr-4 pb-4"
                      style={{ background: "white" }}
                    >
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...formik.values,
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            <div
                              className="d-flex flex-column font-weight-medium text-dark-75"
                              role="group"
                              aria-labelledby="checkbox-group"
                            >
                              {_.entries(values)
                                .filter(([key, value]) =>
                                  [
                                    "broadcast",
                                    "editSpeaker",
                                    "editAccount",
                                    "acceptProgram",
                                    "editProgram",
                                    "useTTS",
                                    "managerArea",
                                    "managerRadioStation",
                                    "managerAccountProvider",
                                    "managerProvider",
                                  ].includes(key)
                                )
                                .map(([key, value]) => {
                                  let disabled = !currentUser?.[key];

                                  return (
                                    <span
                                      key={key}
                                      style={{ color: "#4A5677" }}
                                      className={`align-content-centerNormal_13 mt-4 ${disabled ? "d-none" : "d-flex"
                                        }`}
                                    >
                                      <CheckBox
                                        onCheck={() => {
                                          formik
                                            .getFieldHelpers(key)
                                            .setValue(true);
                                          console.log(value);
                                        }}
                                        onUncheck={() => {
                                          formik
                                            .getFieldHelpers(key)
                                            .setValue(false);
                                          console.log(value);
                                        }}
                                        checked={!!value}
                                        key={key}
                                        className={"mr-4 mt-2"}
                                        label={t(`${key}`)}
                                      />
                                      <span className="font-weight-bold">
                                        {t(`${key}`)}
                                      </span>
                                    </span>
                                  );
                                })}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </CardDetail>
              </div>
              {/*  */}
              <div className="col-md-6">
                <CardDetail
                  title={t("GeneralInformation")}
                // onClick={receiveOTP}
                // twoFactor={currentUser?.twoFactor}
                // isLoading={loadingOTP}
                >
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("userName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="TaiKhoan"
                          fieldProps={{
                            ...formik.getFieldProps("TaiKhoan"),
                            disabled: isEditing,
                          }}
                          fieldMeta={formik.getFieldMeta("TaiKhoan")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("FullName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Ten"
                          fieldProps={{
                            ...formik.getFieldProps("Ten"),
                          }}
                          fieldMeta={formik.getFieldMeta("Ten")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("PhoneNumber")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="SDT"
                          fieldProps={{
                            ...formik.getFieldProps("SDT"),
                          }}
                          fieldMeta={formik.getFieldMeta("SDT")}
                          type="tel"
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        Email
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Email"
                          fieldProps={{
                            ...formik.getFieldProps("Email"),
                          }}
                          fieldMeta={formik.getFieldMeta("Email")}
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("Area")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        {/* <SelectArea
                          rootAreaId={currentUser?.MaKhuVuc}
                          selectedArea={_.forOwn(
                            {
                              ...listArea.find(
                                (area) => area.MaKhuVuc == formik.values.MaKhuVuc
                              ),
                            },
                            (value, key, object) => {
                              if (key == "MaKhuVuc") object["MaKhuVuc"] = value;
                              else if (key == "Ten")
                                object["Ten"] = value;
                            }
                          )}
                          onSelect={(area: Area) => {
                            if (area["MaKhuVuc"]) area["MaKhuVuc"] = area["MaKhuVuc"];
                            formik.getFieldHelpers("MaKhuVuc").setValue(area.MaKhuVuc);
                          }}
                        /> */}
                        <TreeItemDropdown
                          additionalToggleClassname="text-left"
                          currentItem={findNode(allAreas, account?.MaKhuVuc)}
                          tree={allAreas}
                        />
                      </div>
                    </div>
                  </div>
                </CardDetail>
                <CardDetail title={t("SpeechSynthesis")}>
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("LimitedNumberOfCharactersForTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          type="number"
                          name="vbeeWordLimit"
                          fieldProps={{
                            ...formik.getFieldProps("vbeeWordLimit"),
                            disabled: !formik.values.vbee,
                          }}
                          fieldMeta={formik.getFieldMeta("vbeeWordLimit")}
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("NumberOfCharactersUsedInTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          type="number"
                          name="vbeeWordUsed"
                          fieldProps={{
                            ...formik.getFieldProps("vbeeWordUsed"),
                            disabled: !formik.values.vbee,
                          }}
                          fieldMeta={formik.getFieldMeta("vbeeWordUsed")}
                        />
                      </div>
                    </div>

                    <div className="bg-white d-flex flex-column">
                      <div className="d-flex flex-row justify-content-center pt-4 pb-4 align-items-center border mt-4">
                        <div className="labelInput mr-5 mb-0">
                          {t("VBEEAccountConfiguration")}
                        </div>
                        <ReactSwitch
                          onChange={(checked) => {
                            if (checked) {
                              formik.setValues((values) => ({
                                ...values,
                                vbeeWordLimit:
                                  account?.vbee?.monthLimitCharacters,
                                vbeeWordUsed:
                                  account?.vbee?.monthUsedCharacters,
                                vbee: account?.vbee ?? {},
                              }));
                            } else {
                              formik.setValues((values) => ({
                                ...values,
                                vbeeWordLimit: "",
                                vbeeWordUsed: "",
                                vbeeApplicationId: "",
                                vbeeDictionaryId: "",
                                vbeeUsername: "",
                                vbee: null,
                              }));
                            }
                          }}
                          checked={!!formik.values.vbee}
                          className="switch"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor="#3465E5"
                        />
                      </div>
                      <Collapse in={!!formik.values.vbee}>
                        <div style={{ backgroundColor: "#F6F7FB" }}>
                          <div className="row bg-light border border-top-0 mx-0 py-2">
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                              {t("Application ID")}
                            </div>
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                              <BaseTextField
                                className=""
                                name="vbeeApplicationId"
                                fieldMeta={formik.getFieldMeta(
                                  "vbeeApplicationId"
                                )}
                                fieldProps={formik.getFieldProps(
                                  "vbeeApplicationId"
                                )}
                              />
                            </div>
                          </div>

                          <div className="row bg-light border border-top-0 mx-0 py-2">
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                              {t("VBEE Token")}
                            </div>
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                              <BaseTextField
                                className=""
                                name="vbeeToken"
                                fieldMeta={formik.getFieldMeta("vbeeToken")}
                                fieldProps={formik.getFieldProps("vbeeToken")}
                              />
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardDetail>
              </div>
            </div>
          </form>
        </AccountCard>
      </div>
      {/* <ModalUpdateAccount
        show={modalUpdateAccount}
        onClose={() => setModalUpdateAccount(false)}
        submitOnclick={() => formik.handleSubmit()}
        loading={loading}
      />
      <ModalDeleteAccount
        show={modalDeleteAccount}
        onClose={() => setModalDeleteAccount(false)}
      />      */}
      <ModalDeleteAccount
        show={modalDeleteShowing}
        onClose={hideDeleteModal}
        message={t("SureDeleteAccounts")}
        onSubmit={async () => {
          try {
            setDelteLoading(true);
            let { result } = (await userApi.deleteUsers([id])).data;
            if (result == "success") {
              ToastHelper.showSuccess(t("DeleteUserSuccess"));
              setDelteLoading(false);
              history.replace("/users/manager");
            } else {
              setDelteLoading(false);

              ToastHelper.showSuccess(t("DeleteUserFailed"));
            }
          } catch (error) { }
          setDelteLoading(false);

          hideDeleteModal();
        }}
      />
    </AppPage>
  );
}
