import axiosClient from "./axiosClient";

const deviceApi = {
  getDevices: () => {
    const url = `/devices`;
    return axiosClient.get(url);
  },
  getNoConfigDevices: (areaId, params) => {
    const url = `/devices/noConfig/${areaId}`;
    return axiosClient.get(url, { params });
  },

  getDeviceById: (id) => {
    const url = `/devices/${id}`;
    return axiosClient.get(url);
  },
  updateDevice: (params) => {
    const url = `/devices/update`;
    return axiosClient.post(url, params);
  },
  // setVolume: (params) => {
  //   const url = `/devices/volume`;
  //   return axiosClient.post(url, params);
  // },
  delete: (params) => {
    // debugger;
    const url = `/devices/delete`;
    return axiosClient.post(url, params);
  },
  stop: (params) => {
    const url = `/devices/stop`;
    return axiosClient.post(url, params);
  },
  getDeviceSchedule: (deviceId, params) => {
    const url = `/bulletins/device/${deviceId}`;
    return axiosClient.get(url, { params });
  },
};

export default deviceApi;
